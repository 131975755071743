import React, { useState } from "react"
import styled from "styled-components"
import { PrimaryTitle } from "src/components/Typography"
import wpHttpClient from "src/utils/wpHttpClient"
import Message from "src/components/Message"

export default () => {
  const formDataDefaults = {
    name: "",
    email: "",
    message: "",
    agreement: false,
  }

  const responseDefaults = {
    type: null,
    message: null,
  }

  const [response, setResponse] = useState(responseDefaults)
  const [formData, setFormData] = useState(formDataDefaults)

  const handleInputChange = event => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name
    setFormData(Object.assign({}, formData, { [name]: value }))
  }

  const onSubmit = async e => {
    e.preventDefault()

    setResponse(responseDefaults)

    try {
      const results = await wpHttpClient.post(
        "air/v1/site/contact_form",
        formData
      )
      setResponse({
        type: "success",
        message: results.data,
      })
      setFormData(formDataDefaults)
    } catch (e) {
      setResponse({
        type: "error",
        message: e.response.data.message,
      })
    }
  }
  return (
    <Wrapper
      onSubmit={onSubmit}
      className={response.type === "success" ? "-success" : ""}
    >
      <Inner>
        <FieldWrapper>
          <Input
            type="text"
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </FieldWrapper>
        <FieldWrapper>
          <Input
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </FieldWrapper>
        <FieldWrapper>
          <Input
            as="textarea"
            placeholder="Message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
          />
        </FieldWrapper>
        <FieldWrapper>
          <Agreement>
            <AgreementInput
              type="checkbox"
              name="agreement"
              checked={formData.agreement}
              onChange={handleInputChange}
              required
            />
            <AgreementControl />
            <AgreementLabel>I accept terms and conditions</AgreementLabel>
          </Agreement>
        </FieldWrapper>
        <FieldWrapper>
          <Button as="button" type="submit">
            Send
          </Button>
        </FieldWrapper>
      </Inner>
      {response.message && <Message {...response} />}
    </Wrapper>
  )
}

const Wrapper = styled.form`
  &.-success {
    p {
      margin-top: 50px;
    }
  }
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${Wrapper}.-success & {
    display: none;
  }
`

const FieldWrapper = styled.div`
  width: calc(50% - 15px);
  margin-bottom: 20px;

  &:nth-child(3),
  &:nth-child(4) {
    width: 100%;
  }
`

const Input = styled.input`
  width: 100%;
  padding: 9px 0;
  border-bottom: 2px solid #000;
  border-radius: 0;
  font-size: 16px;

  textarea& {
    height: 95px;
    resize: vertical;
  }

  &::placeholder {
    color: currentColor;
    transition: 0.1s;
  }

  &:focus {
    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }
`

const Agreement = styled.label`
  display: flex;
  margin-top: -18px;
  margin-bottom: 12px;
  position: relative;
  font-size: 14px;
`

const AgreementInput = styled.input`
  width: 1px;
  height: 1px;
  position: absolute;
  top: 10px;
  left: 10px;
  background: transparent;
  pointer-events: none;

  &:checked {
    background: blue;
  }
`

const AgreementControl = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 10px;
  border: 2px solid;

  &::before {
    content: "";
    visibility: hidden;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 4px;
    left: 4px;
    background: currentColor;
  }

  ${AgreementInput}:checked ~ &::before {
    visibility: visible;
  }
`

const AgreementLabel = styled.span`
  color: rgba(0, 0, 0, 0.5);
  user-select: none;
`

const Button = styled(PrimaryTitle)``
