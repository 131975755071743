import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import parseContent from "src/utils/parseContent"
import SEO from "src/components/SEO"
import Header from "src/components/Header"
import Hero from "src/components/Hero"
import Container from "src/components/Container"
import ContactForm from "src/components/ContactForm"
import { get } from "lodash"

export default () => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query contactQuery {
        allWordpressPage(filter: { slug: { eq: "contact" } }) {
          nodes {
            featured_media {
              ...MediaFragment
            }
            title
            content
          }
        }
      }
    `
  )
  const { title, featured_media, content } = allWordpressPage.nodes[0]

  return (
    <>
      <SEO title={title} />
      <Wrapper>
        <Header />
        <Hero title={title} image={featured_media} />
        <StyledContainer>
          <Column>
            <Title>Contact us</Title>
            <ContactForm />
          </Column>
          <Column>{parseContent(content)}</Column>
        </StyledContainer>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const StyledContainer = styled(Container)`
  margin-bottom: 50px;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    font-family: ${p => p.theme.font.primary};

    &:first-of-type {
      margin-top: 30px;
    }

    &:nth-of-type(n + 2) {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  ${media.tablet} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 95px;
    padding-top: 6px;
  }
`

const Column = styled.div`
  &:last-child {
    margin-top: 50px;
  }

  ${media.tablet} {
    &:first-child {
      width: calc((100% - 70px) / 8 * 4 + 30px);
    }

    &:last-child {
      width: calc((100% - 70px) / 8 * 3 + 20px);
      margin-top: 0;
    }
  }
`

const Title = styled.h2`
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 17px;
`
