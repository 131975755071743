import React from "react"
// import { Link } from "gatsby"
import Media from "src/components/Media"
import styled from "styled-components"
import Container from "src/components/Container"
import { PrimaryTitle } from "src/components/Typography"

export default ({ title, image }) => {
  return (
    <Wrapper>
      <Background>
        {image && (
          <Media type="fluid" media={image} size="wordpress_2048x2048" />
        )}
      </Background>
      <StyledContainer>
        <PrimaryTitle as="h1">{title}</PrimaryTitle>
      </StyledContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 440px;
  overflow: hidden;
  margin-top: -70px;
  margin-bottom: 76px;
  position: relative;
  color: #fff;
`

const Background = styled.div`
  pointer-events: none;

  &,
  &::before,
  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &::before,
  &::after {
    content: "";
  }

  &::before {
    background: rgba(0, 0, 0, 0.2);
  }

  &::after {
    opacity: 0.5;
    height: calc(100% - 70px);
    top: 70px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const Image = styled(Media)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 74px;
  position: relative;
  z-index: 1;
`
